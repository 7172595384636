import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/PostLayout/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Misconceptions about React`}</h1>
    <p>{`We are all aware that drama and framework-fanboyism will never go away and there are many misconceptions around certain technologies. This post is not to convince anyone to use `}<a parentName="p" {...{
        "href": "https://reactjs.org/"
      }}>{`React`}</a>{`, but to get rid of misconceptions. There is nothing worse, than reading a tweet and a blog post, that has clear errors of understanding.`}</p>
    <p>{`Let’s dive right into this.`}</p>
    <hr></hr>
    <h2>{`1. React needs transpiling`}</h2>
    <p>{`This one is a fun one. You can use React completely without any transpiling step with `}<a parentName="p" {...{
        "href": "https://babeljs.io/"
      }}><inlineCode parentName="a">{`babel`}</inlineCode></a>{` or `}<a parentName="p" {...{
        "href": "webpack"
      }}><inlineCode parentName="a">{`webpack`}</inlineCode></a>{`. I have done many small widgets and tools for clients, that got integrated into Shopify stores, Wordpress pages and even applications running on other frameworks, just by loading the react and react-dom libs and adding a `}<inlineCode parentName="p">{`script`}</inlineCode>{` tag.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script src="https://unpkg.com/react@16/umd/react.production.min.js"></script>
<script src="https://unpkg.com/react-dom@16/umd/react-dom.production.min.js"></script>

<script>
  const e = React.createElement;

  function SliderImage() {
    return e(
      'div',
      { className: 'tSlider--image-wrapper' },
      e('img', {
        src: 'something',
        className: 'tSlider--image',
      })
    );
  }

  function Text() {
    return e(
      'div',
      { className: 'tSlider--sliderText' },
      e('h1', { className: 'tSlider--headline' }, 'Fun stuff'),
      e(
        'span',
        { className: 'tSlider--subHeadline' },
        'This is a React example without any transpiling ',
        e('b', null, 'with a bold text'),
        '. ❤️'
      )
    );
  }

  function TeduschSlider() {
    return e('div', { className: 'tSlider' }, e(SliderImage), e(Text));
  }

  const domContainer = document.getElementById('slider');
  ReactDOM.render(e(TeduschSlider), domContainer);
</script>
`}</code></pre>
    <p>{`This will render a header with an image and a little bit of text. All we used here is the `}<inlineCode parentName="p">{`React.createElement('div')`}</inlineCode>{` function which is equivalent to JSX in the format of `}<inlineCode parentName="p">{`<div></div>`}</inlineCode>{`.`}</p>
    <p><strong parentName="p">{`It is very well possible to write React without transpilers.`}</strong></p>
    <p><em parentName="p">{`Disclaimer: Because many have mentioned this to me, this is of course not a good way of writing a React component. It is valid one, but writing JSX, which is synthetic sugar for exactly that, is much more efficient.`}</em></p>
    <hr></hr>
    <h2>{`2. React is not inspectable / not real HTML`}</h2>
    <p>{`The result of the code above is this HTML code, copied out of the browser:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="tSlider">
  <div class="tSlider--image-wrapper">
    <img src="/some/path" class="tSlider--image" />
  </div>
  <div class="tSlider--sliderText">
    <h1 class="tSlider--headline">Fun stuff</h1>
    <span class="tSlider--subHeadline">
      This is a React example without any transpiling <b>with a bold text</b>.
      ❤️
    </span>
  </div>
</div>
`}</code></pre>
    <p>{`This is how the Elements tab in Chrome DevTools shows it.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "54.95689655172414%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsSAAALEgHS3X78AAABa0lEQVQoz4VRy5KbMBD0pwQhjR4gIYQkngYZr5dlbW/2mv//kQg2l9RSSddU1xy6NT2jk3YDzirIbSySWemDCQ8X7mG6TuOsbU9VjbhBoooclVFDvphlJyVzlPzAKdorxZjkZSVdY/vgrV3a0peqr2Rn5GhVqRUAMApCcEbpSSmFEMJ/QDChAOR8fZ2X++11eaxvH+vy8/H+cX//fD4i3tY1cj8MUsrvZiAA3XR1dTM6XRvdVtrbioqMc/EFznmSJFF9YM4KLX2vbVPosiuEyblXfDBZWShCWUwLjANQQshBbMaYOV/7eR0uyzN0n6G+j/7XrXvOwxymummVccDFwWQCNE2RKvQ4jt7X52kypa698841G1tVFCnaTnswmQBLUSJyOb/cLpfQtZ2tqtJ6EBKlaFeQLeDeHMTGOM2Ns/0EZEPcAijDm+cvfDPvr7IsL9oReJZu34433pv/mKOGCmnDInQVw+N/Ipp/A2zKc83NxLtOAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "React generated Markup in Chrome DevTools",
          "title": "React generated Markup in Chrome DevTools",
          "src": "/static/3b1330be9903b1d6e973561799fa105e/fea0e/mar-dom-render.png",
          "srcSet": ["/static/3b1330be9903b1d6e973561799fa105e/064a4/mar-dom-render.png 225w", "/static/3b1330be9903b1d6e973561799fa105e/44727/mar-dom-render.png 450w", "/static/3b1330be9903b1d6e973561799fa105e/fea0e/mar-dom-render.png 900w", "/static/3b1330be9903b1d6e973561799fa105e/7173d/mar-dom-render.png 928w"],
          "sizes": "(max-width: 900px) 100vw, 900px"
        }}></img>{`
    `}</span></p>
    <p><strong parentName="p">{`Looks a lot like HTML to me and inspectable like vanilla HTML.`}</strong></p>
    <hr></hr>
    <h2>{`3. React promotes inline-styles`}</h2>
    <p>{`I have worked on a lot of applications and with many clients and I have very rarely seen people use inline-styles with React. The rare cases, where to add background-images dynamically or to change the colour depending on certain actions, although there are better solutions for those.`}</p>
    <p>{`It is true that React has many CSS-in-JS solutions, to just list a few: `}<a parentName="p" {...{
        "href": "https://emotion.sh/"
      }}>{`emotion`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.styled-components.com/"
      }}>{`styled-components`}</a>{` and so many more. Each one of these have their own flavour of writing CSS. Some even have multiple valid and recommended ways.`}</p>
    <p>{`The most popular way to start a React project is `}<inlineCode parentName="p">{`create-react-app`}</inlineCode>{` by the React team. It comes with three options out of the box: CSS, SCSS and css-modules (many would consider this, just normal CSS).`}</p>
    <p>{`If you are using a bundler like webpack, all you need to do is import a CSS file and the styles will be automatically added. A simple `}<inlineCode parentName="p">{`import './styles.css'`}</inlineCode>{` is enough.`}</p>
    <p><strong parentName="p">{`The way how styling is handled in React makes it diverse and every team/person can decide by themselves, what the correct and best approach is. There is no dogmatisation.`}</strong></p>
    <hr></hr>
    <h2>{`4. React doesn’t use web standards`}</h2>
    <p>{`This one is my favourite and the most difficult to argue for or against. The term web standards is very arbitrary, but what the most people were referring to is HTML, CSS and JS.`}</p>
    <p>{`Well here is where things get weird. React uses only JS and the ReactDom Reconciler turns that into HTML nodes, CSS is being injected into the browser if needed or loaded at the beginning.`}</p>
    <p><strong parentName="p">{`Now this is pure web standards in my eyes.`}</strong></p>
    <hr></hr>
    <h2>{`5. React is not accessible`}</h2>
    <p>{`This misconception comes from many developers being uninterested in writing accessible applications. This behaviour exists across all frameworks and technologies. It is a general problem of current web development.`}</p>
    <p>{`The lack of resources and trainings, also the pressure from Product Management or CEOs, who do not care about accessibility contributes to this happening. It appears to be a React problem, because React is by far the most used library currently.`}</p>
    <p>{`Projects in the React ecosystem worth mentioning here are especially `}<a parentName="p" {...{
        "href": "https://github.com/downshift-js/downshift"
      }}>{`Downshift`}</a>{` and `}<a parentName="p" {...{
        "href": "https://ui.reach.tech/"
      }}>{`ReachUI`}</a>{` by the great Kent C. Dodds and Ryan Florence. Their efforts to educate developers in these fields are great. If you are using eslint adding `}<a parentName="p" {...{
        "href": "https://github.com/evcohen/eslint-plugin-jsx-a11y"
      }}>{`eslint-plugin-jsx-a11y`}</a>{` to your config will greatly with your accessibility efforts.`}</p>
    <p><strong parentName="p">{`We need to have a general discussion in the web development world about accessibility.`}</strong></p>
    <hr></hr>
    <h2>{`6. React is too difficult to learn`}</h2>
    <p>{`If you want to use every single concept in the React APIs, then yes you may be right. But many of these APIs are not necessary for many developers. The last addition to the React ecosystem that changed how we write code dramatically was the Hooks update in React v16.8.0, but it didn’t take away the possibility to keep writing in the old way (eg. with Classes).`}</p>
    <p>{`React introduced a new Context API, a new way of working with `}<inlineCode parentName="p">{`refs`}</inlineCode>{` and changed the lifecycle hooks. I am going to be honest here, I have been working on the `}<a parentName="p" {...{
        "href": "https://app.thehomelike.com/"
      }}>{`Homelike App`}</a>{` for a year after those releases and I did not use any of the three until a month ago, where I added a Context for the first time in an internal app, so I don’t have to add the Redux boilerplate to the app.`}</p>
    <p><strong parentName="p">{`If you just want to write React with Classes go ahead. Want to work with Functions use `}<inlineCode parentName="strong">{`React.createElement`}</inlineCode>{`. React gives you the possibility to write however you want.`}</strong></p>
    <hr></hr>
    <p>{`In conclusion, I wish for everyone to approach other technologies with the respect they deserve. All of these projects exist, because people are passionate about them. If you don’t want to use them, that is up to you. But don’t flame others for their decisions.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      